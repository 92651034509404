import React from 'react';
import FadeInWrapper from './FadeInWrapper'
const YouTubePlayer = ({title, description, medialink, html}) => {
  return(
    <div className="videoCard" id={title}>
      <FadeInWrapper id={title} percentage={66}>
        <div>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </FadeInWrapper>
      <FadeInWrapper id={`1${title}`} percentage={66}>
        <div className="videoWrapper">
          <iframe title={title} width="560" height="349" src={`${medialink}?autoplay&controls=1&modestbranding`} allowFullScreen></iframe>
        </div>
        </FadeInWrapper>
      <FadeInWrapper id={`2${title}`} percentage={66}>
          <section
          dangerouslySetInnerHTML={{ __html: html }}
          itemProp="articleBody"
        />
        </FadeInWrapper>
    </div>
  )
}
export default YouTubePlayer;
