import React, {forwardRef} from 'react';
import { Link } from "react-scroll";
import {GiSoundWaves} from 'react-icons/gi'


const MediaHeader = forwardRef((props, ref) => {
  let posts = props.posts;

  return(
    <div className="mediaHeader flex center column" ref={ref}>
      <div className="headline">
        <h3>A selection of</h3>
        <h1>MAGNUS</h1>  
      </div>
      
      <ul className="list">
      {posts.map(post => {
        return(
            <li key={post.node.frontmatter.title}>
              <Link
               to={`${post.node.frontmatter.title}`}
               alt={`To ${post.node.frontmatter.title}`}
               smooth={true}
               duration={400}
               className="flex row"
               >
              <GiSoundWaves size={28} /><p>{post.node.frontmatter.title}</p>
               </Link>
            </li>
        )
      })}
      </ul>
    </div>
  )
})
export default MediaHeader;
