import React from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MediaHeader from '../components/MediaHeader'
import YouTubePlayer from '../components/YouTubePlayer'
import SoundcloudPlayer from '../components/SoundcloudPlayer'

const Media = ({data, location}) => {
  const posts = data.allmarkdown.edges;

  return (
    <Layout location={location} title="Magnus Media">
        <Seo title="Media" />
        <div className="media">
            <MediaHeader posts={posts}/>
            { posts.map((edge,i) => {
              let title = edge.node.frontmatter.title;
              let description = edge.node.frontmatter.description;
              let medialink = edge.node.frontmatter.medialink;
              let html = edge.node.html;
              if(medialink.indexOf("youtube") !== -1) {
                return (
                  <YouTubePlayer key={title} title={title} description={description} medialink={medialink} html={html}/>
                )
              } else if (medialink.indexOf("soundcloud") !== -1) {
                return (
                  <SoundcloudPlayer key={title} title={title} description={description} medialink={medialink} html={html}/>
                )
              } else {
                return (
                  <div><h1>Empty media list</h1></div>
                )
              }
            })}
        </div>
    </Layout>
  )
}
export default Media;
export const mediaQuery = graphql`
  query MediaGathering {
    allmarkdown: allMarkdownRemark(
      limit: 100
      sort: { fields: [frontmatter___position], order: ASC }
      filter: { frontmatter: { category: { eq: "media"} } }
    ) {
      edges {
        node {
          html
          frontmatter {
            category
            medialink
            title
            description
            position
          }
        }
      }
    }
 }
`
