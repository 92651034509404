import React from 'react';
import FadeInWrapper from './FadeInWrapper'
const SoundcloudPlayer = ({title, description, medialink, html}) => {
  return(
    <div className="videoCard" id={title}>
      <FadeInWrapper id={title} percentage={66}>
        <div>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </FadeInWrapper>
      <FadeInWrapper id={`1${title}`} percentage={66}>
        <div className="soundcloudWrapper">
          <iframe title={title} width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src={`${medialink}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"`}></iframe>
        </div>
      </FadeInWrapper>
      <FadeInWrapper id={`2${title}`} percentage={66}>
            <section
            dangerouslySetInnerHTML={{ __html: html }}
            itemProp="articleBody"
          />
      </FadeInWrapper>
    </div>
  )
}
export default SoundcloudPlayer;
